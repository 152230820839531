<template>
  <v-container pa-0 fluid fill-height class="px-0 mx-auto">
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center pa-3>
        <!-- <v-layout style="width: 100%">
          <v-btn to="/signup" style="margin-left: -5px" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-layout> -->

        <v-layout
          column
          align-center
          style="width: 100%; margin-top: -25px"
          pb-4
        >
          <v-img :src="logoSrcDark" max-width="150" max-height="33"></v-img>
        </v-layout>
        <v-row justify="space-between">
          <v-col v-if="!isMobileDevice" cols="12" sm="5">
            <v-img src="/img/login/login.png" height="100%"> </v-img>
            <v-img
              src="/img/logo/charged_logo.png"
              width="200"
              class="charged-logo-mini"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="5">
            <v-card
              flat
              class="pa-2"
              style="height: 100%; width: 100%"
              :dark="dark"
            >
              <v-layout
                :class="!isMobileDevice ? 'form-signup' : 'form-signup-mobile'"
              >
                <v-row>
                  <v-icon
                    v-if="SMSsent"
                    style="
                      color: black !important;
                      padding: 1rem;
                      margin-bottom: 1rem;
                    "
                    >mdi-chevron-left</v-icon
                  >
                  <v-spacer></v-spacer>
                </v-row>
                <v-row align="center" justify="space-between" class="mb-2">
                  <v-btn class="text-left" to="/signup" left icon>
                    <v-icon
                      class="text-charged-grey-100"
                      style="
                        color: black !important;
                        font-size: 30px !important;
                      "
                      >mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-col cols="12" md="11">
                    <span
                      :class="
                        !isMobileDevice
                          ? 'login-title font-montserrat text-2xl text-charged-grey-100 font-bold tracking-tight'
                          : 'login-title-mobile font-montserrat'
                      "
                      v-if="!SMSsent"
                      >{{
                        getLanguage == "id"
                          ? "Lengkapi Akun Anda"
                          : "Complete your account"
                      }}</span
                    >
                  </v-col>
                  <!-- <v-col cols="1" class="px-0 hidden md:table">
                    <v-btn @click="profileFormDialogClosed" right icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col> -->
                </v-row>

                <span
                  style="font-size: 18px"
                  class="font-weight-bold"
                  v-if="SMSsent"
                  >{{ lang[getLanguage].THE_CODE }}</span
                >
                <span class="subheading" v-if="SMSsent">{{
                  lang[getLanguage].SMS_SENT + this.phoneNo
                }}</span>
                <span
                  class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
                  >{{ lang[getLanguage].LABEL_FULL_NAME_KTP }}</span
                >
                <v-text-field
                  class="custom-combobox-d color-400"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="displayName"
                  :disabled="disabledName"
                >
                </v-text-field>
                <span
                  class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
                  >{{ lang[getLanguage].SUBSCRIBE_EMAIL_ADDRESS }}</span
                >
                <v-form v-model="valid" ref="form">
                  <v-text-field
                    class="custom-combobox-d color-400"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    v-model="emailAddress"
                    :rules="emailRules"
                    required
                    @input="change0"
                    @change="change0"
                    :disabled="disabledEmail"
                  >
                  </v-text-field>
                </v-form>
                <span
                  class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
                  >{{ lang[getLanguage].LABEL_CUSTOMER_TYPE }}</span
                >
                <v-combobox
                  v-if="customerTypeValue !== 'mitra_driver_gojek'"
                  class="custom-combobox-d color-400"
                  v-model="customerType"
                  :items="customerTypeList"
                  @change="onChangeCustomerType"
                  dense
                  :loading="loading"
                  item-id="id"
                  item-text="name"
                  outlined
                  :disabled="disabledCustomerType"
                ></v-combobox>
                <v-text-field
                  v-if="customerTypeValue == 'mitra_driver_gojek'"
                  class="custom-combobox-d color-400"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="customerType"
                  @change="onChangeCustomerType"
                  :disabled="disabledCustomerType"
                >
                </v-text-field>
                <div v-if="customerTypeValue === 'driver'">
                  <span
                    class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
                  >
                    Referral Code (Optional)
                  </span>
                  <v-combobox
                    class="custom-combobox-d"
                    v-model="referralCode"
                    :items="referralCodeList"
                    dense
                    :loading="loading"
                    item-id="id"
                    item-text="name"
                    outlined
                  ></v-combobox>
                </div>
                <!-- <v-checkbox v-model="newsletter" class="mt-0">
          <template v-slot:label>
            <div class="text-xs md:text-base font-work-sans tracking-tight">
              {{
                getLanguage === "en"
                  ? "Subscribe to our newsletter to get early access to sales and promotions."
                  : "Berlangganan ke newsletter kami untuk mendapatkan akses awal pada penjualan dan promosi."
              }}
            </div>
          </template>
        </v-checkbox> -->
                <v-btn
                  large
                  style="width: 100%"
                  @click="profileSubmit"
                  :loading="loading"
                  :disabled="!displayName || !emailAddress || !customerType"
                  class="submit-button rounded-lg"
                >
                  <!-- <v-icon left>mdi-arrow-right-bold</v-icon> -->
                  {{ lang[getLanguage].SIGNUP_SUBMIT }}
                </v-btn>
                <span class="login-label-policy">
                  {{
                    getLanguage === "en"
                      ? "By registering, you agree with our"
                      : "Dengan masuk, Anda setuju dengan"
                  }}
                  <!-- <span
            style="cursor: pointer; text-decoration: underline; color: #0000d6"
            @click="openPolicy"
            >{{ lang[getLanguage].SALES_AGREEMENT }}</span
          > -->
                  <span
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      color: #0000d6;
                    "
                    @click="routerGo('/term-of-use')"
                  >
                    {{
                      getLanguage == "en"
                        ? "Terms and conditions"
                        : "Syarat & ketentuan"
                    }}
                  </span>
                  {{ lang[getLanguage].LABEL_AND }}
                  <span
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      color: #0000d6;
                    "
                    @click="routerGo('/privacy-policy')"
                    >{{ lang[getLanguage].ACC_APP_PRIVACY
                    }}{{
                      getLanguage === "id" ? " & Cookie kami" : "our Cookies"
                    }}</span
                  >.
                </span>

                <v-layout fill-height></v-layout>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>

        <v-snackbar :timeout="3000" :bottom="'bottom'" v-model="snackbar">
          <v-icon color="green">mdi-check-circle-outline</v-icon>
          <span style="margin-left: 5px">{{
            lang[getLanguage].ACCOUNT_CREATED
          }}</span>
          <!-- <v-btn text color="#00D2D7" @click.native="snackbar = false">Close</v-btn> -->
        </v-snackbar>

        <!-- ################################## DIALOGS ######################################## -->

        <v-dialog v-model="pwdDialog" max-width="400">
          <v-card>
            <v-card-title style="font-size: 16px" class="font-weight-bold"
              >Strong Password Suggestions</v-card-title
            >

            <v-card-text>
              &bull; At least 8 characters—the more characters, the better.<br />
              &bull; A mixture of both uppercase and lowercase letters.<br />
              &bull; A mixture of letters and numbers.<br />
              &bull; Inclusion of at least one special character, e.g., ! @ # ?
              ]
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="cyan darken-1" outlined @click="pwdDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-slide-y-transition>

    <!-- DIALOG LOADER -->
    <v-dialog v-model="isLoad" max-width="300">
      <v-card color="white" class="p-3">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center text-charged-grey-100">
          Please stand by
          <!-- <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import store from '@/store/index'
import { db, auth } from "@/main";
// import VuePassword from 'vue-password'
// import Password from "vue-password-strength-meter";
import zxcvbn from "zxcvbn";
import {
  doc,
  // getDoc,
  getDocs,
  collection,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import {
  LoginUserApi,
  // RegisterUserApi
} from "../../services/back-end-ruby";
// let sha256 = require('sha256')
export default {
  name: "signupnext",
  components: {
    // Password
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
  },
  data() {
    return {
      lang: this.$store.state.lang,
      currentUser: "",
      alert: false,
      loader: null,
      loading: false,
      pwdDialog: false,
      show: true,
      snackbar: false,
      alertMessage: "",
      dataProvided: false,
      valid: false,
      displayName: "",
      nameRules: [
        (v) => !!v || "Display Name is required",
        (v) => (v && v.length > 2) || "Name must be minimal 3 characters",
        (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
      ],
      emailAddress: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email address must be valid",
      ],
      noEmail: false,
      score: 0,
      password: "",
      secureLength: 8,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Minimal 8 characters",
      ],
      terms: false,
      logoSrcDark: "",
      customerTypeValue: "",
      customerType: "",
      customerTypeList: ["Regular Customer", "Mitra Driver Online"],
      isLoad: false,
      SMSsent: false,
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getCustomerType() {
      return this.$store.getters.getCustomerType;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // setTimeout(() => (this[l] = false), 3000)
    },
  },
  methods: {
    async addUsers() {
      const orderRef = collection(db, "users");
      const q = query(
        orderRef,
        where("phoneNumber", "==", this.currentUser.phoneNumber)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length == 0) {
        this.currentUser = auth.currentUser;
        var newPostKey = doc(collection(db, "users")).id;
        console.log(doc(collection(db, "users")).id);
        await setDoc(doc(db, "users", newPostKey), {
          phoneNumber: this.currentUser.phoneNumber,
        });
      }
    },
    clickNext() {
      // Update Profile
      this.loading = true;
      let vm = this;
      // Update Email first then the display name
      if (this.noEmail) {
        var email = this.currentUser.phoneNumber;
        email = this.currentUser.phoneNumber.substr(1, email.length);
        this.emailAddress = email + "@charged.asia";
      }
      this.currentUser
        .updateEmail(this.emailAddress)
        .then(() => {
          // Update Email successful.
          // Now update the display Name (authentication is cheked before getting here)
          /* this.currentUser.sendEmailVerification().then(() => {
          // Email sent.
        }).catch((error) => {
          // An error happened.
        }) */
          this.currentUser
            .updateProfile({
              displayName: this.displayName,
            })
            .then(
              function () {
                // Update successful.
                console.log("Display Name Updated");
              },
              (error) => {
                // An error happened.
                console.log(error);
                this.alert = true;
                this.alertMessage = error.message;
              }
            );
          console.log("Email Updated");
          this.currentUser
            .updatePassword(this.password)
            .then(() => {
              // alert('Password Set successfully! You can now Login with Phone & Password!')
              let action;
              let dispatchObj = {};
              // this.newPostKey = db.collection("users").doc();
              this.newPostKey = doc(collection(db, "users")).id;
              action = "insertUserForSignUp";
              dispatchObj = {
                uid: this.currentUser.uid,
                postkey: this.newPostKey,
                name: this.displayName,
                email: this.emailAddress,
                accounts: [],
                acc_type: 10, //  Consumer / Customer
                acc_balance: 0,
                language: this.getLanguage,
                status: 1,
                email_verified: false,
                link_verified: false,
                phone_verified: true,
                phone_no: 0,
                level: 1,
                created: new Date().getTime(),
              };
              this.$store
                .dispatch(action, dispatchObj)
                .then(() => {
                  console.log("User Created in db 1");
                })
                .catch((error) => {
                  console.log(error);
                });
              this.currentUser.updateProfile({
                displayName: this.displayName,
              });
              this.$store.commit("SetLanguage", {
                language: this.getLanguage,
              });
              this.snackbar = true;
              setTimeout(() => {
                vm.$router.replace("/welcome");
              }, 2000);
            })
            .catch((error) => {
              console.log(error);
              this.alert = true;
              this.alertMessage = error.message;
              this.loader = null;
              this.loading = false;
            });
        })
        .catch((error) => {
          console.log(error);
          this.alert = true;
          this.alertMessage = error.message;
          this.loader = null;
          this.loading = false;
        });
    },
    clickNextViaGooglePlus() {
      let action;
      let dispatchObj = {};
      let vm = this;
      // this.newPostKey = db.collection("users").doc();
      this.newPostKey = doc(collection(db, "users")).id;
      // action = "insertUserForSignUp";
      dispatchObj = {
        uid: this.currentUser.uid,
        postkey: this.newPostKey,
        name: this.displayName,
        email: this.emailAddress,
        accounts: [],
        acc_type: 10, //  Consumer / Customer
        acc_balance: 0,
        language: this.getLanguage,
        status: 1,
        email_verified: true,
        link_verified: false,
        phone_verified: this.currentUser.phoneNumber !== null,
        phone_no: this.currentUser.phoneNumber,
        level: 1,
        created: new Date().getTime(),
      };
      this.$store
        .dispatch(action, dispatchObj)
        .then(() => {
          console.log("User Created in db 2");
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("SetLanguage", {
        language: this.getLanguage,
      });
      this.snackbar = true;
      setTimeout(() => {
        vm.$router.replace("/welcome");
      }, 2000);
    },
    clickNextViaNumber() {
      let action;
      let dispatchObj = {};
      // let vm = this;
      // this.newPostKey = db.collection("users").doc();
      this.newPostKey = doc(collection(db, "users")).id;

      action = "insertUserForSignUp";
      dispatchObj = {
        uid: this.currentUser.uid,
        postkey: this.newPostKey,
        name: this.displayName,
        email: this.emailAddress,
        accounts: [],
        acc_type: 10, //  Consumer / Customer
        acc_balance: 0,
        language: this.getLanguage,
        status: 1,
        email_verified: true,
        link_verified: false,
        phone_verified: this.currentUser.phoneNumber !== null,
        phone_no: this.currentUser.phoneNumber,
        level: 1,
        created: new Date().getTime(),
      };
      console.log(action);
      console.log(dispatchObj);
      this.$store
        .dispatch(action, dispatchObj)
        .then(() => {
          console.log("User Created in db 3");
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("SetLanguage", {
        language: this.getLanguage,
      });
      this.snackbar = true;
      setTimeout(() => {
        // vm.$router.replace("/myaccount");
      }, 2000);
    },
    showFeedback({ suggestions, warning }) {
      console.log("🙏", suggestions);
      console.log("⚠", warning);
    },
    showScore(score) {
      console.log("💯", score);
      if (score > 2 && this.$refs.form.validate()) {
        this.valid = true;
        this.dataProvided = true;
      } else {
        this.valid = false;
        this.dataProvided = false;
      }
    },
    openTerms() {
      this.$emit("termsClicked");
    },
    openPrivacy() {
      this.$emit("privacyClicked");
    },
    updateScore() {
      // The input event sends the current password and any included user inputs (email in this case).
      console.log(zxcvbn(this.password).score);
      // Calculate the score here either using a custom
      if (zxcvbn(this.password).score > 2 && this.$refs.form.validate()) {
        this.valid = true;
        this.dataProvided = true;
      } else {
        this.valid = false;
        this.dataProvided = false;
      }
      // javascript library or a request to the server.

      // Note: The score must be an integer between 0 and 4.
    },
    change0() {
      // validate
      console.log(this.valid);
      this.alert = false;
      if (this.$refs.form.validate()) {
        this.dataProvided = true;
      } else {
        this.dataProvided = false;
      }
      /* setTimeout(() => {
        this.updateScore()
      }, 100) */
    },
    async profileSubmit() {
      let getDataUser = this.getUser;
      // console.log("THIS_DATA_USERS", getDataUser);
      // let whoCustomerType = "";
      // let howVehicleId = "";
      // if (this.getCustomerType == "mitra_driver_gojek") {
      //   whoCustomerType = "Mitra Driver Gojek";
      //   howVehicleId = "";
      // } else {
      //   whoCustomerType =
      //     this.customerTypeValue == "regular" ? "Non Mitra" : "Mitra";
      //   howVehicleId = getDataUser.bikeSubs?.vehicle_id ?? "";
      // }
      await LoginUserApi({ phone_number: getDataUser.phoneNumber }).then(
        (res) => {
          // console.log("RES_LOGIN", res);
          this.$store.commit("SetUserDetails", {
            ...getDataUser,
            fullName: this.name,
            email: this.email,
            phone: getDataUser.phoneNumber,
            customerType: this.customerTypeValue,
            isLoggedIn: true,
            isLoggedIn2: true,
          });
          if (this.getCustomerType == "mitra_driver_gojek") {
            this.$store.commit("setDataGojekInformasiPribadi", {
              ...this.$store.getters.getDataGojekInformasiPribadi,
              name: this.name,
              emailGojek: this.email,
              phoneNumberGojek: getDataUser.phoneNumber,
            });
          }
          this.$cookies.set("token_api", res.token);
          this.$router.push("/myaccount");
          // if (this.getCustomerType == "mitra_driver_gojek") {
          //   this.isLoad = false;
          //   // this.loadingScreen = false;
          //   this.$router.push("/form-subscribe-mitra-gojek");
          // } else {
          //   this.$router.push("/form-subscribe");
          // }
        }
      );
    },
    onChangeCustomerTypeValue() {
      if (this.customerTypeValue === "regular")
        this.customerType = "Regular Customer";
      if (this.customerTypeValue === "driver")
        this.customerType = "Mitra Driver Online";
      if (this.customerTypeValue === "mitra_driver_gojek")
        this.customerType = "Mitra Gojek";
    },
    onChangeCustomerType() {
      if (this.customerType === "Regular Customer")
        this.customerTypeValue = "regular";
      if (this.customerType === "Mitra Driver Online")
        this.customerTypeValue = "driver";
    },
  },
  async created() {
    this.isLoad = true;
    console.log("### CREATED ###");
    console.log("GET_USER", this.getUser);
    this.$store.commit("HideNav");
    this.logoSrcDark = "./img/charged_logo.png";
    // this.currentUser = auth.currentUser;
    // console.log(this.currentUser);
    // let vm = this;
    // if (!this.currentUser) {
    //   vm.$router.push("/");
    //   return;
    // }
    this.displayName = this.getUser.displayName;
    this.emailAddress = this.getUser.email;
    this.customerTypeValue = this.getUser.customerType;
    if (this.customerTypeValue !== "") {
      this.isLoad = false;
    }
    if (this.customerTypeValue === "non_mitra")
      this.customerType = "Regular Customer";
    if (this.customerTypeValue === "mitra")
      this.customerType = "Mitra Driver Online";
    if (this.customerTypeValue === "mitra_driver_gojek")
      this.customerType = "Mitra Gojek";
    if (this.currentUser) {
      console.log("displayName set");
      // Check if not Driver Account
      // const docRef = doc(db, "test-rides", this.currentUser.uid);
      // const docSnap = await getDoc(docRef);

      // console.log(docSnap.data())

      // console.log(this.currentUser)

      // if (this.currentUser.providerData[0].providerId === "google.com") {
      //   console.log("Google Sign In user bucket empty");
      //   this.displayName = this.currentUser.displayName;
      //   this.emailAddress = this.currentUser.email;
      //   this.clickNextViaGooglePlus();
      //   return;
      // } else if(this.currentUser.providerData[0].providerId === "phone"){
      //   this.displayName = this.currentUser.displayName;
      //   this.emailAddress = this.currentUser.email;
      //   this.clickNextViaNumber();
      //   this.addUsers()
      //   vm.$router.push("/myaccount");

      // }else {
      //   auth.signOut().then(() => {
      //     // User not exists
      //     vm.$router.push("/");
      //   });
      // }

      //   db.collection("users")
      //     .where("uid", "==", this.currentUser.uid)
      //     .get()
      //     .then((snapshot) => {
      //       if (snapshot.empty) {
      //         console.log("User bucket empty");
      //         console.log(this.currentUser);
      //         if (this.currentUser.providerData[0].providerId === "google.com") {
      //           console.log("Google Sign In user bucket empty");
      //           this.displayName = this.currentUser.displayName;
      //           this.emailAddress = this.currentUser.email;
      //           this.clickNextViaGooglePlus();
      //           return;
      //         } else {
      //           auth.signOut().then(() => {
      //             // User not exists
      //             vm.$router.push("/");
      //           });
      //         }
      //       } else {
      //         snapshot.forEach((doc) => {
      //           // User exists
      //           this.newPostKey = doc;
      //           this.userData = doc.data();
      //           this.$store.commit("SetUserDetails", {
      //             uid: this.currentUser.uid,
      //             docId: this.newPostKey.id,
      //             language: this.userData.language,
      //             displayName: this.userData.name,
      //             aboutMe: this.userData.about_me,
      //             address: this.userData.address,
      //             fullName: this.userData.full_name,
      //             dob: this.userData.date_of_birth,
      //             sex: this.userData.sex,
      //             email: this.userData.email,
      //             gravatar: this.userData.gravatar,
      //             avatar: this.userData.avatar,
      //             isLoggedIn: true,
      //             isVerified: this.currentUser.emailVerified,
      //             idVerified: this.userData.id_verified,
      //             phoneVerified: this.userData.phone_verified,
      //             phoneNo: this.userData.phone_no,
      //             accLevel: this.userData.acc_level,
      //             accType: this.userData.acc_type,
      //             accStatus: this.userData.status,
      //             accBalance: this.userData.acc_balance,
      //             memberSince: this.userData.created,
      //             favorites: this.userData.interests_favorites,
      //             myapplications: this.userData.myapplications,
      //             // location: this.userData.location
      //           });
      //           if (doc.data().acc_type === 1) {
      //             // || doc.data().acc_type === 2 || doc.data().acc_type === 3
      //             this.$store
      //               .dispatch("updateLastLogin", { uid: this.currentUser.uid })
      //               .then(() => {
      //                 console.log("Sign in logged in db");
      //               })
      //               .catch((error) => {
      //                 console.log(error);
      //               });

      //             vm.$router.push("/charged"); // User Homepage
      //           } else if (doc.data().acc_type > 9) {
      //             // || doc.data().acc_type === 2 || doc.data().acc_type === 3
      //             this.$store
      //               .dispatch("updateLastLogin", { uid: this.currentUser.uid })
      //               .then(() => {
      //                 console.log("Sign in logged in db");
      //               })
      //               .catch((error) => {
      //                 console.log(error);
      //               });
      //             var slug = window.localStorage.getItem("lastApplySlug");
      //             console.log("Last Apply Slug");
      //             console.log(slug);
      //             if (slug !== null && slug !== "null") {
      //               vm.$router.push("/careers/" + slug);
      //             } else {
      //               vm.$router.push("/account"); // User Homepage /userhome
      //               return;
      //             }
      //           } else {
      //             console.log("### Firebase REROUTED ###");
      //             auth.signOut().then(() => {
      //               // User not autorized
      //               vm.$router.push("/");
      //             });
      //           }
      //         });
      //       }
      //     })
      //     .catch((err) => {
      //       this.loading = false;
      //       console.log("Error getting documents.", err);
      //     });
      // } else {
      //   console.log(" displayName not set");
      //   this.show = true;
    }
  },
};
</script>
<style>
::placeholder {
  color: #656565;
  font-weight: 500;
}
.myDiv {
  background: url("/img/app/card_bg.jpg") no-repeat;
}
</style>
